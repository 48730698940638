@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Pixeled";
  src: url("https://pc-service.no/fonts/Pixeled.ttf") format("truetype");
}

.border-image-button-black {
  border-image-source: url("https://pc-service.no/assets/black_background.png");
  border-image-slice: 300 fill; /* First number for corners, second for edges */
  border-image-repeat: stretch;
  border-image-width: 20px;
}

.border-image-button-green {
  border-image-source: url("https://pc-service.no/assets/green_background.png");
  border-image-slice: 300 fill; /* First number for corners, second for edges */
  border-image-repeat: stretch;
  border-image-width: 20px;
}

.border-image-button-gray {
  border-image-source: url("https://pc-service.no/assets/gray_background.png");
  border-image-slice: 300 fill; /* First number for corners, second for edges */
  border-image-repeat: stretch;
  border-image-width: 20px;
}

/* .service-card {
  background: rgb(203, 249, 188);
  background: linear-gradient(
    90deg,
    rgba(203, 249, 188, 1) 0%,
    rgba(163, 230, 191, 1) 68%,
    rgba(129, 201, 176, 1) 89%,
    rgba(28, 213, 164, 1) 100%
  );
} */

.service-card {
  background: rgb(203, 249, 188);
  background: linear-gradient(
    90deg,
    rgba(28, 213, 164, 1) 0%,
    rgba(129, 201, 176, 1) 11%,
    rgba(163, 230, 191, 1) 32%,
    rgba(203, 249, 188, 1) 100%
  );
}

.slider-marquee {
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
